import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Iframe from 'src/Components/IFrame';
import Spacer from 'src/Components/Spacer';


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Mudafossil',
	year: 2016,
	slug: 'mudafossil',
	order: 2,
	image: images['faces.png']
};
export { meta };


export default function ({ slug}) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Mudafossil'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.jpg']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2016)'}
				materials="Music Video w/ Ash Koosha"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Mudafossil"
						url="https://player.vimeo.com/video/162308621?title=0&byline=0&portrait=0&color=ffffff"
					/>
					<Spacer>
						<p>
              Directed by Dalena Tran &  Hirad Sab<br />
              Produced by Ninja Tune & sooz.global<br />
              Music by Ash Koosha from album <i>I AKA I</i>
						</p>
						<h4>Featured</h4>
						<br />
						<p>2016 - <a href="https://www.youtube.com/watch?v=6kSm7saCoXQ" target="_blank" rel="noopener noreferrer">Adult Swim</a> - Broadcast + Online</p>
						<p>2016 - <a href="https://vimeo.com/167348895" target="_blank" rel="noopener noreferrer">Synthetic Resonances</a> - London, UK</p>
					</Spacer>
				</Column>
			</ProjectColumns>
		</Project>
	);
}

