/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect, useRef} from 'react';
import Image from 'src/Components/Image';
import { Link } from 'react-router-dom';

import {
	gridItem, navProjCont, navProjImg, navProjTitle
} from './style.module.scss';

const GridItem = ({ item, idx, colSize, randoms, count, imagesPerWindow, offset,  isVertical, isMobile}) => {
	const rIdx = idx * 4;

	const img = item.meta.image;
	const imgAspect = img.height / img.width;

	let  imgWidth;
	let  imgHeight;
	let  imgLeft;
	let  imgTop;

	// if(!isMobile){
	imgWidth = colSize * (randoms[rIdx] * 0.5 + 0.6);
	imgHeight = imgWidth * imgAspect;
	
	imgLeft = (idx * colSize) - (imgWidth / 2);
	imgLeft = imgLeft - ((((count - 1) * colSize) - (colSize * imagesPerWindow)) / 2);

	imgTop = (window.innerHeight / 2 - imgHeight / 2) + (imgHeight * 0.8 * randoms[rIdx + 1] * (randoms[rIdx + 2] > 0.5 ? -1 : 1));
	imgTop = Math.abs(imgTop);
	// }
	// else{
	if (isVertical){
		imgHeight = (window.innerHeight / 3) * (randoms[rIdx] * 0.3 + 0.6);
		imgWidth = imgHeight / imgAspect;
	
		imgLeft = (window.innerWidth - imgWidth) * randoms[rIdx + 1];
		imgLeft = Math.abs(imgLeft);

		imgTop = (window.innerHeight * idx / 3) + imgHeight;
	}

	if(isMobile && !isVertical){
		imgLeft = (idx * colSize) + (imgWidth);
	}
	// 	else{
	// 		imgWidth = colSize * (randoms[rIdx] * 0.5 + 0.6);
	// 		imgHeight = imgWidth * imgAspect;
	
	// 		imgLeft = (idx * colSize) + (imgWidth);

	// 		imgTop = (window.innerHeight / 2 - imgHeight / 2) + (imgHeight * 0.8 * randoms[rIdx + 1] * (randoms[rIdx + 2] > 0.5 ? -1 : 1));
	// 		imgTop = Math.abs(imgTop);
	// 	}
	// }



	


	const gridItemStyle = randoms.length > 0 ? {
		width: imgWidth,
		height: 'auto',
		// top: isVertical ? (imgTop + offset) * (randoms[rIdx + 3] * 0.2 + 0.8) : imgTop,
		top: imgTop,
		// left: (imgLeft + offset),
		left: isVertical ? imgLeft : (imgLeft + offset) * (randoms[rIdx + 3] * 0.2 + 0.8),
		zIndex: 999,
		paddingBottom: isVertical ? `${imgHeight}px` : 0
	} : {};


	return (
		<>
			{'float' in item.meta ?
				<div style={gridItemStyle} className={gridItem}>
					<div className={navProjCont}>
						<Image className={navProjImg}
							img={img}
							alt=''
						/>
						<div className={navProjTitle}>{item.meta.title}</div>
					</div>
				</div>
				:
				<Link to={`/${item.meta.slug}`} style={gridItemStyle} className={gridItem}>
					<div className={navProjCont}>
						<Image className={navProjImg}
							img={img}
							alt=''
						/>
						<div className={navProjTitle} style={{ '--opacity': isMobile ? '1' : '0' }}>
							{item.meta.title}
						</div>
					</div>

				</Link>
			}
		</>
	);

};

export default GridItem;
