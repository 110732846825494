import React, { useRef, useEffect } from 'react';
// import backImg from "./back.png";


import '../../common/main.css';

import ScrollIndicator from 'src/Components/ScrollIndicator';

const Project = ({ children, scrollIndicator = false }) => {

	const myRef = useRef(null);

	const executeScroll = () => {
		myRef.current.parentNode.parentNode.scrollIntoView({
			behavior: 'smooth',
			alignToTop: true
		});
	};

	const closeModal = (e) => {
		if (myRef.current.contains(e.target)) {
			return;
		}

		// history.goBack();
	};

	useEffect(() => {
		executeScroll();
		document.addEventListener('mousedown', closeModal);

		return () => {
			document.removeEventListener('mousedown', closeModal);
		};
	}, []);

	return (
		<div>
			<div className="modalBlur"></div>
			<div className="modalWrap">
				{/* <div><img src={backImg}/></div> */}
				<div className="modal" ref={myRef}>
					{scrollIndicator && <ScrollIndicator />}
					{children}
				</div>
			</div>
		</div>
	);
};


export default Project;
