import React from 'react';
import Imggrad from '../imgGrad/index.js';

const GridRect = ({ idx, colSize, itemToSquareRatio, randoms, count, imagesPerWindow, offset, isVertical, isMobile }) => {
	const rIdx = idx * 4;


	let sqrSize1;
	let sqrSize2;
	let sqrWidth;
	let sqrHeight;
	let sqrTop;

	sqrSize1 = window.innerWidth * (randoms[rIdx] * 0.15 + 0.1);
	sqrSize2 = window.innerWidth * (randoms[rIdx + 1] * 0.15 + 0.1);
	sqrWidth = randoms[rIdx + 2] > 0.5 ? sqrSize1 : sqrSize2;
	sqrHeight = randoms[rIdx + 2] > 0.5 ? sqrSize2 : sqrSize1;

	// console.log("test");
	// const sqrTop = (window.innerHeight / 2 - sqrSize / 2) ;
	sqrTop = (window.innerHeight / 2 - sqrHeight / 2) + (sqrHeight * 0.8 * randoms[rIdx + 2] * (randoms[rIdx + 1] > 0.5 ? -1 : 1));

	let sqrLeft = (idx * itemToSquareRatio) * colSize - sqrWidth / 2;
	if (!isMobile) {
		sqrLeft -= (((count - 1) * colSize) - (colSize * imagesPerWindow)) / 2;
	}

	if (isVertical){
		sqrTop = (window.innerHeight * idx * 2 / 3) + sqrHeight;
		sqrLeft = (window.innerWidth - sqrWidth) * randoms[rIdx + 1];
		sqrLeft = Math.abs(sqrLeft);
	}

	const gridSqrStyle = randoms.length > 0 ? {
		position: 'absolute',
		background: '#ffffff80',
		width: sqrWidth,
		height: sqrHeight,
		top: sqrTop,
		left: isVertical ? sqrLeft : sqrLeft + (offset * (randoms[rIdx + 3] * 0.1 + 0.9)),
		zIndex: -999,
		transition: 'none'
	} : {};


	return (
		<div key={`sqr-${idx}`} style={gridSqrStyle}>
			<Imggrad />
		</div>
	);

};

export default GridRect;
