import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
// import Vimeo from "src/Components/VideoVimeo";
import Image from 'src/Components/Image';
import Iframe from 'src/Components/IFrame';
import ImageCaption from 'src/Components/ImageCaption';
import Spacer from 'src/Components/Spacer';
import {
	cones,

} from './style.module.css';

import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'city / worship city',
	year: 2016,
	slug: 'city-worship-city',
	order: 10,
	image: images['cwc-thumb.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='city / worship city'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2019)'}
				materials="Two-channel Installation, mixed materials"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Image
						img={images['5.png']}
						alt='Documentation from "city / worship city"'
					/>
					<ImageCaption title="city / worship city (2019)" materials="In memory of loss" />
					<p>My older brother Lulu and I watched our childhood building collapse on the news. Three construction workers confirmed dead and twelve more were missing. The camera crew with the help of a helicopter flew over the narrow, neighboring streets and into the mass of dust. I felt uneasy seeing traces of a place I knew so well from a distance. The debris was still settling. Our building was veiled but I could make out the temple at the end of the street.</p>
					<p>As a schoolchild, I would stop by the temple’s fountain attached to the northern wall to wash my face and hands before catching my bus to school. For a period of months, the water would taste of blood. When I asked Lulu to try it, he told me it was because the Khenchok family was brutally murdered the week before.</p>

					<p>The Khenchoks lived in the same building as us. They arrived during the civil war that spread through the land they had always lived on. The father came home one evening and pushed his entire family off of their balcony from the 8th floor. Because there was no remaining family to give them a proper burial, their blood has seeped from the ground and into the space in-between: The large wells, where our city sources its water.</p>

					<Spacer>
						<Iframe alt="Tempo Rubato"
							url="https://player.vimeo.com/video/477338782?title=0&byline=0&portrait=0&color=ffffff" aspectRatio={2592 / 1728}
						/>
					</Spacer>

					<p>Though it made no physical sense, it's hard to undo the idea from my head. Especially after Lulu and his friend Sasha went online looking for the surveillance footage from that day. The camera is angled towards the street. The building’s entrance is recognizable but mostly in shadow. You can actually see Mr. Khenchok arriving home from work. He comes into frame from the left and walks into the building. The video is edited forward, with the time stamp two hours ahead. The sun on its way out and headlights sweep across the roads.</p>

					<p>It all seems normal and then an abrupt, large mass comes from the top of the frame and hits the ground. The body is close to the camera and partially out of frame. At first watch, it doesn't look like a human body at all. With no audio to pair the fall, it seemed unreal, composed. If those were limbs, they looked to be in limp, unnatural positions. I got quite angry in that moment.</p>

					<p>“That's Mrs. Khenchok,” Sasha says.</p>

					<p>It all becomes clear. Next, Stella, the daughter, who was my brother’s age, is seen farther away, fallen close to the entrance. Her form is certain. She would often be out on the streets, handing booklets of writing and strange mediated clippings. I learned that day, while contemplating the impact of her death that common concrete can come at you from different directions.</p>

					<p>Stella’s lifeless body on the ground extended with a long pause. I saw the blackened blood spread around her head. It glossed into the cracks and veins on the ground. Her younger brother followed, almost out of frame and much smaller. Then, lastly, her father. The headlights sprawling in and out across their motionless bodies.</p>

					<p>The reason for why Mr. Khenchok did what he did, people speculated, was because he was recently let go from his job laying the cement for the city’s largest high-rise project. A week prior to this incident, the police raided the construction site asking to see papers. Many of them were working there illegally, and most of them escaped before they were arrested or deported. But the contractor couldn't take any more risks.</p>
					<Spacer>
						<Iframe alt="Tempo Rubato"
							url="https://player.vimeo.com/video/477339014?title=0&byline=0&portrait=0&color=ffffff" aspectRatio={2592 / 1728}
						/>
					</Spacer>

					<p>Later, my father described what my brother said as nonsense. The taste of blood in the water came from excess rain that year during our monsoon. It was the iron from the soil that washed into the city’s water. It did seem more plausible, and somehow, even now, I remember the sky was often dark and wet those days.</p>

					<p>Stella always carried with her a smoking device. When I questioned her about it, “It's just the way I am,” she said, handing me several heavy pages pierced together by a thick thread, smoke exiting the edges of her mouth and nostrils.</p>

					<p>“Well, is it okay if I try?” I ask, politely.</p>

					<p>She starts to cough, tired and exasperated.</p>

					<p>“Why? What for?”</p>

					<p> “I'd like to know how you feel”</p>

					<p>“What business is it of yours to know how I feel?”</p>

					<p>I was hurt then, and it stung many times afterwards when I would see her outside of our building’s entrance. But the disquiet resonated more now realizing that that was the only interaction I've ever had with her.</p>
					<Spacer>
						<Image className={cones}
							img={images['cwc1.png']}
							alt='Documentation from "city / worship city"'
						/>
					</Spacer>

					<p>On the bus, I would go through the little book of wood pulp pages. They were documents of things: Little clips of paper, film, and plastic. It's hard to describe what they had in common because their materials were not always the same and the subjects had no real correlation. The hand-writings were by different people with different messages:</p>

					<p>“She’s been saying my name wrong the whole time, but I think it’s nicer than not trying”</p>

					<p>“do not touch, they distort under every circumstance”</p>

					<p>“city / human habitation”</p>

					<p> Pierced and appended at the spine were colorful fortunes in special typography that the beggars would often hand out in hopes of receiving money. They were folded into foreign contortions with people’s names written inside. On the back page were two strips of thin yellow duct tape going vertically across the page and written over it in black sharpie:</p>

					<p>“there’s one time & one place, please keep us  moving”</p>

					<p>There were photographs, clippings from encyclopedias, and little notes that looked tossed and found. On a piece of cloth, I stitched “vestiges” and appended it to the cover and gave the book to one of my students many years later.</p>
					<p>My father has a reputation in our family to explain away both small and large mysteries. It calms me down and I appreciate that about him. The mystery of the ferrous water I drank still couldn’tpiece together what I knew of the Khenchoks. I would continue to wash my face in the temple’s fountain. Imagining my ritual of cleansing would invite them to escape the city’s in-between</p>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['6.png']}
						alt='Documentation from "city / worship city"'
					/>
					<ImageCaption title="city / worship city (2019)" materials="In loss of memory" />
					<hr />
					<Spacer>
						<p><i>This piece was originally installed in 2019 at the New Wight Gallery in Los Angeles, CA. In 2020, this work was adapted for "More a Cosmology than a Cartography" on Gallery Platform LA; curated by Ana Iwataki.</i></p>
					</Spacer>
				</Column>
			</ProjectColumns>
		</Project >
	);
}

