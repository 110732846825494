import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Iframe from 'src/Components/IFrame';
import Image from 'src/Components/Image';
import Spacer from 'src/Components/Spacer';


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));


const meta = {
	title: 'Incomplete',
	year: 2021,
	slug: 'incomplete',
	order: 10,
	image: images['incomplete-thumb.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Incomplete'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2021)'}
				materials="Music Video w/ Ash Koosha"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Incomplete"
						url="https://player.vimeo.com/video/537999129?title=0&byline=0&portrait=0&color=ffffff"
					/>
					<Spacer>
						<p>
							Directed by Dalena Tran<br />
							Produced by sooz.global<br />
							Type & Tooling by Hirad Sab<br />
						</p>
						<p>Has the future already happened?</p>
						<p>This may be a reverie of our increasingly virtualized world that is submerged into a directional flow of information and disparate impressions that takes shape as a slow-burning anxiety; a rupture in linear time.</p>
						<p>Composed of a single take, “Incomplete” invites us to traverse an endless choreography of bodies in perpetual free-fall and updating images that reflect a world in constant change.</p>
						<p>This work is an ode to what artist and media theorist Hito Steyerl coins the "poor image." The image which is collected, copied, and processed to the point of disorientation and anonymity. The image which is not one but consists of many while presenting a different perception of coherency.</p>
						<h4>Featured</h4>
						<br />
						<p>2022 - Slamdance Film Festival - Official Selection - Online</p>
						<p>2022 - Vienna Shorts - Official Selection - Vienna, Austria + Online</p>
						<p>2022 - Mammoth Lakes Film Festival - Official Selection - Mammoth Lakes, CA, USA</p>
						<p>2022 - Athens International Film and Video Festival - Official Selection - Athens, OH, USA</p>
						<p>2022 - Boston Underground Film Festival - Official Selection - Boston, MA, USA</p>
						<p>2022 - Kaboom Animation Film Festival - Official Selection - Amsterdam, Netherlands</p>
						<p>2022 - GLAS Animation Festival - Official Selection - Online</p>
						<p>2022 - Ann Arbor Film Festival - Official Selection - Ann Arbor, MI, USA</p>
						<p>2022 - Seeyousound International Music Film Festival - Official Selection - Turin, Italy</p>
						<p>2022 - Brussels Independent Film Festival - Official Selection - Brussels, Belgium</p>
						<p>2022 - Fisura Film and Video Festival - Official Selection - Mexico City, Mexico</p>
						<p>2021 - Istanbul International Experimental Film Festival - Official Selection - Istanbul, Turkey</p>
						<p>2021 - Cucalorus Film Festival - Official Selection - Wilmington, NC, USA</p>
						<p>2021 - FIBER Festival - Official Selection - Amsterdam, Netherlands</p>
						<p>2021 - Experimental Film Guanajuato - Official Selection - Guanajuato, Mexico</p>
						<p>2021 - /’fu:bar/ Glitch Art Festival - Official Selection - Zagreb, Croatia</p>
						<p>2021 - Supernova Digital Animation Festival - Regeneration - Official Selection - Denver, CO, USA</p>
						<p>2021 - <a href="https://www.aqnb.com/2021/06/04/ash-koosha-dalena-tran-tell-stories-with-poor-image-making-algorithms-in-their-video-incomplete/" target="_blank" rel="noopener noreferrer">AQNB</a> - Premiere - Online</p>
						<p>2021 - <a href="https://homeostasislab.org/visualizar/obra/2016" target="_blank" rel="noopener noreferrer">Homeostasis Lab</a> - New Time - Official Selection - Online</p>
						<p>2021 - <a href="https://www.beyondtheshort.com/watch/incomplete-ash-koosha" target="_blank" rel="noopener noreferrer">Beyond the Short</a> - Official Selection - Online</p>
						<p>2021 - <a href="https://vimeo.com/channels/staffpicks/537999129" target="_blank" rel="noopener noreferrer">Vimeo Staff Pick</a> - Best of the Month - Online</p>
						<p>2021 - <a href="https://tv.booooooom.com/2021/06/23/incomplete-dalena-tran/" target="_blank" rel="noopener noreferrer">Boooooom.tv</a> - Official Selection - Online</p>
						<p>2021 - <a href="https://newart.city/show/mdtd-virtual" target="_blank" rel="noopener noreferrer">SAIC Film Festival</a> - My Dreams These Days - Official Selection - Chicago, IL, USA & Online</p>
						<p>2021 - Indie Short Fest - Outstanding Achievement Award in Visual FX - Los Angeles, CA, USA</p>
						<p>2021 - Munich Music Video Awards - Official Selection - Munich, Germany</p>
						<p>2021 - Big Cartoon Festival - Official Selection - Moscow, Russia</p>
						<p>2021 - Simultan Festival - Unstable State of Things - Official Selection - Timişoara, Romania</p>
						<p>2021 - In Touch - CICA Museum - Gimpo, South Korea</p>
					</Spacer>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['4.png']}
						alt='Still from Incomplete'
					/>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['1.png']}
						alt='Still from Incomplete'
					/>
				</Column>
				<Column>
					<Image
						img={images['2.png']}
						alt='Still from Incomplete'
					/>
				</Column>
				<Column>
					<Image
						img={images['3.png']}
						alt='Still from Incomplete'
					/>
				</Column>
			</ProjectColumns>
		</Project>
	);
}

