import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Iframe from 'src/Components/IFrame';
import Button from 'src/Components/Button';
// import Spacer from "src/Components/Spacer";


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Acts in Translation',
	year: 2020,
	slug: 'acts-in-translation',
	order: 3,
	image: images['ait-thumb.png']
};
export { meta };


export default function ({ slug}) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Acts in Translation'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2020)'}
				materials="Generative, HD Video, Sound, Online"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Tempo Rubato"
						url="https://player.vimeo.com/video/411583013?title=0&byline=0&portrait=0&color=ffffff&background=1" aspectRatio = {1920 / 1260}
					/>
					<Button label="View the Work" href="https://acts-in-translation.xyz/" />

					<p>
						<i>Acts in Translation</i> is a generative moving image and sound installation built on the web. On each hour, in Universal Coordinated Time, a story about a broken heart emerges. The unique soundscapes on each page visit are contributions from people and moments around the world. The project was first conceived in February 2020 as countries worldwide, gripped by a global pandemic, restricted citizen mobility. These new restrictions compelled public urban life into domestic spaces. As network and communication technologies take on an increasingly integral role in mediating contemporary life, <i>Acts in Translation</i> is a perpetually processed archive that hybridizes the boundaries of language, embodiment, and locality to consider a renewal for listening that does not aim to populate the senses with new things but rather to see things anew.
					</p>
					<h4>Featured</h4>
					<br />
					<p>2020 - <a href="https://near.rest" target="_blank" rel="noopener noreferrer">NEARREST NEIGHBOR</a> - Online</p>
					<p>2021 - <a href="https://strp.nl/" target="_blank" rel="noopener noreferrer">STRP - All of Us</a> - Online</p>
					<p>2021 - <a href="https://www.roundlemon.co.uk/worm-exhibition" target="_blank" rel="noopener noreferrer">Round Lemon - Worm Exhibition</a> - Online</p>

				</Column>
			</ProjectColumns>
		</Project>
	);
}

