import { importAllImages } from 'src/utils.js';

const images = importAllImages(require.context('../../../common/img', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: '',
	year: 2012,
	slug: 'g3',
	order: 10,
	image: images['d3-red.png'],
	float: ''
};
export { meta };
