import React, { useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
// import { ClientSideComponent } from "react-prerendered-component";


import ReactGA from 'react-ga';

import Seo from 'src/Components/Seo';

import Nav from '../../Components/Nav';
import NavGrid from '../../Components/NavGrid';
import Header from '../../Components/Header';
import ScrollToTop from '../../Components/ScrollToTop';
import NotFound from '../../Containers/NotFound';
import Info from '../../Containers/Info';
import Teaching from '../../Containers/Teaching';
import Discursive from '../../Containers/Discursive';
// import Writing from '../../Containers/Writing';
import Footer from '../../Components/Footer';

// import GutterLoading from '../../Components/GutterLoading';
// const Gutter = React.lazy(() => import('../../Components/Gutter'));

const cache = {};

function importAll(r, cache) {
	r.keys().forEach(key => {
		const category = key.split('/')[1];
		const component = key.split('/')[2];
		if (!(category in cache)) {
			cache[category] = {};
		}
		cache[category][component] = r(key);
	});
}

importAll(require.context('../../work/', true, /index\.js$/), cache);

ReactGA.initialize('UA-44906000-1');


const App = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	return (
		<>
			<ScrollToTop />
			<div className="app">
				{/* <a className="sr-only" href="#main">Skip to main content</a> */}
				<Switch>
					{/* Home */}
					<Route path='/' exact>
						<Seo />
						<div className='barCont'>
							<Header />
							<Nav categories={cache} />
						</div>
						{/* <NavGrid categories={cache} category={'Artwork'} imgPerWindow={5} mouseSpeed={0.005} itemToSquareRatio={2} /> */}
						<Info />
						<Footer />
					</Route>

					{/* Posts and Pages */}
					{
						Object.keys(cache).map((category) => {
							return Object.keys(cache[category]).map((item) => {
								const current = cache[category][item];
								const meta = current.meta;
								const Comp = current.default;
								if (!('external' in meta) && !('float' in meta)) {
									// const Comp = current.default;
									return (
										<Route path={`/${meta.slug}`} key={meta.slug}>

											<Header />
											<Nav categories={cache} />
											<Comp />
											<Footer />
										</Route>
									);
								}
								return '';
							});
						})
					}

					{/* Gutter */}
					{/* <Route path={`/gutter`} >
            <Seo
              title={'GUTTER'}
              description="Visual refuse for the retina: traces, detritus, iteration."
              path={'gutter'}
              twitter="@dalenaxtran"
            />
            <Suspense fallback={GutterLoading()}>
              <Gutter />
            </Suspense>
          </Route> */}

					{
						Object.keys(cache).map((_category) => {
							const category = _category.toLowerCase();
							if (category !== 'teaching' && category !== 'discursive') return (
								<Route path={`/${category}`} key={category}>
									<Seo
										title={_category}
										path={category}
										twitter="@dalenaxtran"
									/>
									<Header />
									<Nav categories={cache} />
									<NavGrid categories={cache} category={_category} imgPerWindow={5} mouseSpeed={0.01} itemToSquareRatio={2} />
									<Footer />
								</Route>
							);
							else return (null);
						})
					}

					<Route path="/teaching">
						<Seo
							title={'Teaching'}
							path={'teaching'}
							twitter="@dalenaxtran"
						/>
						<Header />
						<Nav categories={cache} />
						<Teaching />
						<Footer />
					</Route>
					<Route path="/discursive">
						<Seo
							title={'Discursive'}
							path={'discursive'}
							twitter="@dalenaxtran"
						/>
						<Header />
						<Nav categories={cache} />
						<Discursive />
						<Footer />
					</Route>

					<Route path="/info">
						<Seo
							title={'Info'}
							path={'info'}
							twitter="@dalenaxtran"
						/>
						<Header />
						<Nav categories={cache} />
						<Info />
						<Footer />
					</Route>

					{/* Unmatched */}
					<Route path="*">
						<Seo />
						<NotFound />
					</Route>
				</Switch>


			</div>
		</>
	);
};

export default () => (
	<Router>
		<App />
	</Router>
);