import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
// import Vimeo from "src/Components/VideoVimeo";
import Image from 'src/Components/Image';
import Iframe from 'src/Components/IFrame';
import ImageCaption from 'src/Components/ImageCaption';
// import Spacer from "src/Components/Spacer";


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Tempo Rubato',
	year: 2021,
	slug: 'tempo-rubato',
	order: 10,
	image: images['tr-thumb.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Tempo Rubato'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2021)'}
				materials="Ongoing experiments on stolen time"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Tempo Rubato"
						url="https://player.vimeo.com/video/477345436?title=0&byline=0&portrait=0&color=ffffff&background=1"
					/>
					<ImageCaption title="Tempo Rubato" materials="Lower Panel, Projected video of Gare d’Orsay" />
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['4.png']}
						alt='3D Render of windows'
					/>
				</Column>
				<Column>
					<Image
						img={images['3.png']}
						alt='3D Render of windows'
					/>
				</Column>
				<Column>
					<Image
						img={images['5.png']}
						alt='3D Render of windows'
					/>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['2.png']}
						alt='3D Render of windows'
					/>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['ogimage.png']}
						alt='3D Render of windows'
					/>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Iframe alt="Tempo Rubato"
						url="https://player.vimeo.com/video/477345927?title=0&byline=0&portrait=0&color=ffffff&background=1"
					/>
				</Column>
			</ProjectColumns>
		</Project>
	);
}

