import React from 'react';

// import {
//   container,
//   headerMaterials,
//   fluid,
//   headerTitle
// } from './style.module.scss';

import {
	container,
	fluid,
} from '../../common/main.css';

const ProjectHeader = ({title, year, materials, fluidLayout, className = ''}) => (
	<div className={`${container} ${className} ${fluidLayout ? fluid : ''}`}>
		{/* <h2> */}
		{/* <span className={headerArtist}>{artistName}</span> */}
		{/* <span className={headerTitle}>{title}</span> */}
		{/* </h2> */}
		<div className="projTitle">
			<h2>{title} <sup>{year}</sup></h2>
		</div>
		<div className="headerMaterials">
			<h3>{materials}</h3>
		</div>
		{/* <div className={headerLink}>
      <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
    </div> */}
	</div>
);

export default ProjectHeader;
