import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Iframe from 'src/Components/IFrame';
import Image from 'src/Components/Image';
import Spacer from 'src/Components/Spacer';


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Harm',
	year: 2022,
	slug: 'harm',
	order: 10,
	image: images['harm-thumb.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Harm'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.jpg']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2022)'}
				materials="Music Video w/ Beacon"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Harm"
						url="https://player.vimeo.com/video/752560752?title=0&byline=0&portrait=0&color=ffffff"
					/>
					<Spacer>
						<p>
							Directed by Dalena Tran<br />
							Produced by Beacon<br />
							Character 3D Modeling and Animation by Kat Sung<br />
							Music by Beacon from album <i>Along the Lethe</i><br /><br />
							<a href="https://www.girlsinfilm.net/videos/beacon-harm" target="_blank" rel="noopener noreferrer">Read more about the work</a>
						</p>
					</Spacer>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['7.png']}
						alt='Still from Harm'
					/>
				</Column>
				<Column>
					<Image
						img={images['8.png']}
						alt='Still from Harm'
					/>
				</Column>
				<Column>
					<Image
						img={images['9.jpg']}
						alt='Still from Harm'
					/>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Image
					img={images['4.png']}
					alt='Still from Harm'
				/>
				<Image
					img={images['3.png']}
					alt='Still from Harm'
				/>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['6.jpg']}
						alt='Still from Harm'
					/>
				</Column>
				<Column>
					<Image
						img={images['1.png']}
						alt='Still from Harm'
					/>
				</Column>
				<Column>
					<Image
						img={images['2.png']}
						alt='Still from Harm'
					/>
				</Column>
			</ProjectColumns>
		</Project>
	);
}

