import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import ImageCaption from 'src/Components/ImageCaption';
import Image from 'src/Components/Image';
import Spacer from 'src/Components/Spacer';


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Into the Violet Belly',
	year: 2022,
	slug: 'into-the-violet-belly',
	order: 10,
	image: images['itvb-thumb2.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='Harm'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2022)'}
				materials="Collaboration w/ Thuy-Han Nguyen-Chi"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Spacer>
						<Image
							img={images['2.png']}
							alt='Into the Violet Belly Still'
						/>
					</Spacer>
					<Spacer>
						<p><i>Into the Violet Belly</i> is a film a part of a larger installation by Thuy-Han Nguyen-Chi entitled <i>This undreamt of sail is watered by the white wind of the abyss.</i> Installed initially at the Hamburger Bahnof for the 12th Berlin Biennale, the work has additionally been adapted for cinema and live-performance.</p>
						<p><i>Interweaving family lore, mythology, science fiction, and digital abstraction, Thuy-Han Nguyen-Chi's film follows the collaboration between the artist and her mother, Thuyen Hoa, who fled Vietnam after the end of the American War via a near-calamitous sea journey. Oscillating between voices, visual registers, and timescales—was it seven months or seven thousand years?— Into The Violet Belly offers up an image of its multiplicitous structure: a massive digital swarm, tiny avatars of migrating bodies, swimming in an infinite blue. — 60th New York Film Festival</i></p>

						<h4>Featured</h4>
						<br />
						<p>2022 - <a href="https://post.moma.org/into-the-void-of-metamorphosis-thuy-han-nguyen-chi-and-wong-binghao-in-conversation/" target="_blank" rel="noopener noreferrer">post.moma.org</a> - Online</p>
						<p>2022 - New York Film Festival - Official Selection - New York City, NY, USA</p>
						<p>2022 - San Diego Asian Film Festival  - Official Selection - San Diego, CA, USA</p>
						<p>2022 - Singapore International Film Festival - Official Selection - Singapore</p>
						<p>2022 - super feelings episode 3 - de Appel - Amsterdam, The Netherlands
						</p>
						<p>2022 - The London Open - Whitechapel Gallery - London, England</p>
						<p>2022 - Berlin Biennale - Installation - Berlin, Germany</p>
					</Spacer>
					<Spacer>
						<Image
							img={images['1.jpg']}
							alt='This undreamt of sail is watered by the white wind of the abyss Installation'
						/>
						<ImageCaption title="This undreamt of sail is watered by the white wind of the abyss (2022)" materials="installation view, 12th Berlin Biennale, Hamburger Bahnof, photo by Jens Franke" />
					</Spacer>
				</Column>
			</ProjectColumns>
			<ProjectColumns>
				<Column>
					<Image
						img={images['6.png']}
						alt='Into the Violet Belly Still'
					/>

					<Image
						img={images['10.png']}
						alt='Into the Violet Belly Still'
					/>
					<Image
						img={images['8.png']}
						alt='Into the Violet Belly Still'
					/>
					<Image
						img={images['9.png']}
						alt='Into the Violet Belly Still'
					/>
					<Image
						img={images['7.png']}
						alt='Into the Violet Belly Still'
					/>
					<Image
						img={images['5.png']}
						alt='Into the Violet Belly Still'
					/>
					<ImageCaption title="Into the Violet Belly (2022)" materials="film stills. Courtesy of Thuy-Han Nguyen-Chi" />
				</Column>
				<Column>
					<h4>Film Credits</h4>
					<p className='lAlign'>
						Directors -  Thuy-Han Nguyen-Chi, Thuyen Hoa<br />
						Animation and Computer Graphics -  Dalena Tran<br />
						Producers -  Thuy-Han Nguyen-Chi, Maria Pham<br />
						Cast -  Anonymous, Anonymous, Chicken, Jellyfish<br />
						Assistant Director -  Johannes Lehnen<br />
						Cinematographer -  Hoang-Son Doan<br />
						Camera Assistant -  Makrem Ayari<br />
						Underwater Cinematographer -  Alexandra Brixy<br />
						Underwater Camera Assistant -  Saskia Luft<br />
						Safety Divers -  Catalin Bindu, Luciano Ellul, Marco Spinedi<br />
						Photographer -  Julian Irlinger<br />
						Location Scouts -  Dymar Angileri, Maria Pham, Thuy-Han Nguyen-Chi<br />
						Film Editor -  Liyo Gong<br />
						Sound Editor -  Valentin Mazingarbe<br />
						Sound Recordist -  Mads Ulriksen<br />
						Field Recordist -  Francesco Fabris<br />
						Composer -  Vincent Yuen Ruiz<br />
						Bass Flautist -  Louise Hjorth Hansen<br />
						Cellist -  Flavia Passigli<br />
						Double Bassist -  Vincent Yuen Ruiz<br />
						Flautist -  Elisa Azzara<br />
						Percussionists -  Greta Eacott, Szymon Gąsiorek, Francesco Toninelli<br />
						Violist -  Pauline Hogstrand<br />
						Viola da Gamba Player -  Nicole Hogstrand<br />
						Sound Engineer (SEWING BODIES) -  Tommy Kamp Vestergaard<br />
						Mixing (SEWING BODIES) -  Jesper Nørbæk<br />
						Mastering (SEWING BODIES) -  Joel Krozer<br />
						Composer, Performer, Producer (song) -  Franziska Aigner<br />
						Poetry (CHANT CHỮ CHẾT) -  Quyen Nguyen-Hoang<br />
						Mixing (song) -  Tim Roth<br />
						Mixing (sonic composition) -  Enyang Urbiks<br />
						Mastering (song and sonic composition) -  Enyang Urbiks<br />
						Colorist -  Loup Brenta<br />
						Story (ONE HUNDRED EGGS) -  Thich Nhat Hanh<br />
						Subtitle Translator -  Thuy-Han Nguyen-Chi<br />
						Proofreader and Editor -  Bassem Saad<br />
						Subtitle Editor -  Thuy-Han Nguyen-Chi
					</p>
				</Column>
			</ProjectColumns>
		</Project >
	);
}

