import React from 'react';
// import { Link } from 'react-router-dom';
import '../../common/main.css';
const Teaching = () => (
	<div className="teaching">
		<div>
			<h2>
				Teaching
			</h2>
			<br />
			<h4>Spring 2023</h4>
			<p>
				<a href="https://dalena.github.io/fvea318-sp23/" target="_blank" rel="noopener noreferrer">Animating Virtual Worlds - CalArts - Experimental Animation</a>
			</p>
			<br />
			<h4>Spring 2022</h4>
			<p>
				<a href="https://dalena.github.io/desma159B/" target="_blank" rel="noopener noreferrer">Video and Animation Capstone Senior Projects - UCLA - DMA</a>
			</p>
			<br />

			<h4>Spring 2021</h4>
			<p>
				<a href="https://dalena.github.io/art4101-sp21/" target="_blank" rel="noopener noreferrer">Moving Image Art - OSU - Art + Technology</a>
			</p>
			<p>
				<a href="https://dalena.github.io/art3001/" target="_blank" rel="noopener noreferrer">Internet Art - OSU - Art + Technology</a>
			</p>
			<p>
				<a href="https://hsab.github.io/art4401/" target="_blank" rel="noopener noreferrer">Computer Animation - OSU - Art + Technology</a>
			</p>
			<p>
				<a href="https://dalena.github.io/art5001/" target="_blank" rel="noopener noreferrer">Sound & Image - OSU - Art + Technology</a>
			</p>
			<p>
				<a href="https://u.osu.edu/radicantbodies/" target="_blank" rel="noopener noreferrer"><i>Radicant Bodies</i></a> - OSU Art + Technology - Student Works 👁️‍🗨️
			</p>
			<br />
			<h4>Fall 2020</h4>
			<p>
				<a href="https://dalena.github.io/art4101-fa20/" target="_blank" rel="noopener noreferrer">Moving Image Art - OSU - Art + Technology</a>
			</p>
			<p>
				<a href="https://hsab.github.io/art4401-au20/" target="_blank" rel="noopener noreferrer">Computer Animation - OSU - Art + Technology</a>
			</p>
			<br />
			<h4>Summer 2020</h4>
			<p>
				<a href="https://hsab.github.io/desma24/" target="_blank" rel="noopener noreferrer">Motion - UCLA - DMA</a>
			</p>
			<br />
			<h4>Spring 2019</h4>
			<p>
				<a href="https://dalena.github.io/desma171/" target="_blank" rel="noopener noreferrer">Compressed Cinema w/ Casey Reas - Teaching Assistant - UCLA - DMA </a>
			</p>
			<br />
			<h4>Spring 2019</h4>
			<p>
				<a href="https://github.com/dalena/No_Place_Spaces_0_2019" target="_blank" rel="noopener noreferrer">Interferences Workshop - OSU - Knowlton School of Architecture</a>
			</p>
			<br />
		</div>
	</div>
);

export default Teaching;