import React from 'react';
import '../../common/main.css';


const Discursive = () => (
	<div className="teaching">
		<div>
			<h2>
				Discursive
			</h2>
			<br />
			<h4>Proposals</h4>
			<br />

			<div className='btnCont'>
				<div className="btnVint">
					<a href="https://codec.earth/" target="_blank" rel="noopener noreferrer"><h4>Whole Earth Codec</h4><h5> (2023 - present)</h5></a>
				</div>
				<div className="btnVint">
					<a href="https://vivarium.host/" target="_blank" rel="noopener noreferrer"><h4>Vivarium</h4><h5> (2023)</h5></a>
				</div>
			</div>
			<br />

			<h4>Writing</h4>
			<br />

			<p>2022 - <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ad.2797" target="_blank" rel="noopener noreferrer">Images of Former Futures and Reformations</a>, Architectural Design (AD), Alt-Form: Indeterminacy and Disorder, edited by Viola Ago, Wiley Publishing</p>
			<br />

			<p>2021 - <a href="https://killscreen.com/jump-cuts/" target="_blank" rel="noopener noreferrer">Jump cuts</a>, Kilscreen, edited by Alex Westfall</p>			<br />

			{/* <p>2019 - <a href="https://projects.flatjournal.com/flat/tran/" target="_blank" rel="noopener noreferrer">The Delay</a>, Flat Journal, Issue 0 FLAT, edited by Isla Hansen & Chandler McWilliams</p> */}
		</div>
	</div>
);

export default Discursive;