import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Iframe from 'src/Components/IFrame';
import Image from 'src/Components/Image';
import ImageCaption from 'src/Components/ImageCaption';
import Spacer from 'src/Components/Spacer';

import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'Binary Dreaming',
	year: 2016 - 2017,
	slug: 'binary-dreaming',
	order: 900,
	image: images['bd-thumb.png']
};
export { meta };


export default function ({ slug }) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description="“Binary Dreaming” is a collaborative audiovisual performance"
				path={slug}
				contentType="video.other"
				twitter="@dalenaxtran"
				image={images['still13.jpg']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2016-2017)'}
				materials="3D Rendering, Animation, Real-time Rendering"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Iframe alt="Tempo Rubato"
						url="https://player.vimeo.com/video/249608737?title=0&byline=0&portrait=0&color=ffffff"
					/>
					<ImageCaption title="Binary Dreaming" materials="Performance recording" />
					<p>
						<i>Binary Dreaming</i> was a collaborative audiovisual live performance exploring the continuous morphogenesis of sound and visual flow. The project was developed in late 2015, and was performed throughout Europe and US during 2016. This video intends to document the performance as it was experienced by the participants. Music by Ash Koosha. Visual work in collaboration with Hirad Sab.
					</p>
					<h4>Featured</h4>
					<br/>
					<p>2016 - Institute of Contemporary Art - London, UK</p>
					<p>2016 - Pohoda Festival - Trenčin, Slovakia</p>
					<p>2016 - Museum of Modern Art PS1 - Arizona, AZ</p>
					<p>2016 - FORM Festival, - New York City, NY</p>
					<p>2016 - TEDxOslo - Oslo, Norway</p>
					<p>2016 - Convergence Festival - London, UK</p>
					<p>2016 - Bozar Night - Brussels, Belgium</p>
					<p>2016 - Phono Festival - Odense, Denmark</p>
				</Column>
			</ProjectColumns>

			<ProjectColumns>
				<Column>
					<Spacer>
						<Image
							img={images['still-3.png']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still3.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still-1.png']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still7.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still9.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still11.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still12.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>

				</Column>
				<Column>
					<Spacer>
						<Image
							img={images['still2.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still-2.png']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still1.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still8.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still10.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still15.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
					<Spacer>
						<Image
							img={images['still13.jpg']}
							alt='Still image from "Binary Dreaming"'
						/>
					</Spacer>
				</Column>
			</ProjectColumns>
			<Spacer></Spacer>
		</Project>
	);
}

