import React, { useRef } from 'react';
import {
	Link,
} from 'react-router-dom';
import order from 'src/work/order';

import { navMenu } from '../../common/main.css';

const Nav = ({ categories}) => {
	const navEl = useRef(null);
	const orderedCategories = Object.keys(categories).sort(
		(a, b) => {
			return order.indexOf(a) - order.indexOf(b);
		}
	);

	return (
		<nav className={navMenu} ref={navEl}>
			{orderedCategories.map(
				function (category, idx) {
					return (
						<div key={category} id={idx}>
							<Link to={category.toLowerCase()}>{category}</Link>
						</div>
					);
				})
			}
		</nav>
	);
};

export default Nav;
