import React, { useState, useRef,useEffect} from 'react';

import {
	container,
	frame,
	poster as posterClass,
	title as titleClass,
	open
} from './style.module.css';

const IFrame = ({
	url,
	title = 'iframeContent',
	frameBorder = 0,
	poster = null,
	className = '',
	aspectRatio = 16 / 9,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(!poster);
	const [iWidth, setIWidth] = useState(null);
	const stageCanvasRef = useRef(null);


	useEffect(() => {
		setIWidth(stageCanvasRef.current.offsetWidth);

		function handleResize() {
			setIWidth(stageCanvasRef.current.offsetWidth);
		}
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className={`${container} ${className}`} ref={stageCanvasRef} style={{paddingBottom: `calc(${1 / aspectRatio} * 100%)`}}>
			{poster && <img
				alt={title}
				className={`${posterClass} ${isOpen ? open : ''}`}
				onClick={() => setIsOpen(true)}
				src={poster.src}
				srcSet={poster.srcSet}
			/>}
			{poster && <button
				className={`${titleClass} ${isOpen ? open : ''}`}
				onClick={() => setIsOpen(true)}>{title}
			</button>}
			{isOpen && <iframe
				src={url}
				className={frame}
				title={title}
				frameBorder={frameBorder}
				style={{width: `${iWidth}px`, height: `${iWidth / aspectRatio}px`}}
				{...props}
			></iframe>}
		</div>
	);
};

export default IFrame;
