import React from 'react';
import '../../common/main.css';
const Info = () => (
	<>
		<div className="modalBlur"></div>
		<div className="info">
			<div>
				<p>
					Dalena Tran designs systems and builds platforms to propose new models for collective knowledge and creative worldbuilding.
					She is the co-founder of Fuser Studio, a creative platform ecosystem of AI tools for augmenting media production workflows.
				</p>
				<br />
				<p>
					Her body of work explores the interplay between media and emerging technology in constructing urban imaginaries. She has taught courses in media arts and technology at California Institute of Arts, Ohio State University, and UCLA Design Media Arts. She currently sits on the editorial board of Flat Journal.
				</p>
				<br />
				<p>
					Tran's projects have been featured and screened internationally, including New York Film Festival, MoMA PS1, Adult Swim, Slamdance, Gallery Platform LA, and ICA London. Her research has received support from organizations like Antikythera, Berggruen Institute, One Project, Serpentine Arts Technologies, RadicalxChange, STRP, and PACT Zollverein.
				</p>
				<br />
				{/* <p>She is currently interested in the liminal properties of translation as a cultural and technological force. </p>
			<br /> */}
				<br />
				{/* <p>
				I'm currently interested in liminal properties of translation as a cultural and technological force. Reach out to reach me~
			</p> */}
				<p>
					<a target="_blank" rel="noopener noreferrer" href="mailto:info@dalena.me">info[at]dalena.me</a>
					<br />
					@dalenaxtran
				</p>
			</div>
		</div></>
);

export default Info;