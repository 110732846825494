import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
	return (
		<div className="footer">
			{/* <h1>➽</h1> */}
			<h1><Link to={'/'}>➽</Link></h1>
		</div>
	);
};

export default Footer;