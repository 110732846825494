import React from 'react';
// import { Link } from "react-router-dom";
import {
	a,
	buttonWrap,
	button
} from './style.module.scss';

const Button = ({href, label, handleClick }) => (
	<div className={buttonWrap}>
		<a href={href} target="_blank" rel="noopener noreferrer"><button
			className={button}
			onClick={handleClick}
		>
			{label}
		</button>
		</a>
	</div>
);

export default Button;