import React from 'react';
import { importAllImages } from 'src/utils.js';
import Project from 'src/Containers/Project';
import ProjectHeader from 'src/Components/ProjectHeader';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Image from 'src/Components/Image';
import Button from 'src/Components/Button';
import Spacer from 'src/Components/Spacer';


import Seo from 'src/Components/Seo';

const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: 'What Time is it Here?',
	year: 2016,
	slug: 'what-time-is-it-here',
	order: 3,
	image: images['wtiit-thumb.png']
};
export { meta };


export default function ({slug}) {
	return (
		<Project>
			<Seo
				title={meta.title}
				description='What Time is it Here?'
				path={slug}
				twitter="@dalenaxtran"
				image={images['ogimage.png']}
			/>
			<ProjectHeader
				artistName={'Dalena Tran'}
				title={meta.title}
				year={'(2016)'}
				materials="Interactive Non-Linear Narrative, Twine, Custom CSS/JS"
				link="https://dalena.me"
			/>
			<ProjectColumns>
				<Column>
					<Spacer>
						<Image
							img={images['ogimage.png']}
							alt='What Time is it Here?'
						/>
					</Spacer>
					<p>A search party of one. <i>What Time is it Here?</i> is an interactive narrative about a person with a diminishing memory who wakes up one morning in search of the time only to discover a world that moves with or without them. </p>
					<p>Dedicated to my grandfather who shared with me the tradition of storytelling and who was still alive when I wrote this story. He died late April 2020.</p>
					<p>Music taken from The Caretaker’s <i>An Empty Bliss Beyond This World</i></p>
					<Button label="View the Work" href="https://dalena.github.io/search-party/" />
				</Column>
			</ProjectColumns>
		</Project>
	);
}

