import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');
// const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;


if (rootElement.hasChildNodes()) {
	ReactDOM.hydrate(
		<React.StrictMode>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</React.StrictMode>,
		rootElement
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</React.StrictMode>,
		rootElement
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
