// import { importAllImages } from 'src/utils.js';


// const images = importAllImages(require.context('./assets', false, /\.(png|jpe?g|svg)$/));

const meta = {
	title: '',
	year: 2012,
	slug: 'g8',
	order: 10,
	// image: images['g8.png'],
	float: ''
};
export { meta };

