import React from 'react';
// import bg3 from "./bg5.png";
import {
	imgGrad,
	// imgImg
} from './style.module.scss';

const Imggrad = () => {
	return (
		<div className={imgGrad}>
			{/* <img className={imgImg} src={bg3}/> */}
		</div>
	);
};
  
export default Imggrad;