import React, { useRef, useState, useEffect } from 'react';
import GridItem from './gridItem';
import GridRect from './gridRect';
import {
	navGrid
} from './style.module.scss';
import { isMobile } from 'react-device-detect';

function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

const NavGrid = ({ categories, category, imgPerWindow, mouseSpeed, itemToSquareRatio }) => {

	const requestRef = useRef();
	const divRef = useRef();
	const mouseX = useRef();
	const easedX = useRef();
	const verticalThreshold = 768;
	const [isVertical, setIsVertical] = useState(window.innerWidth < verticalThreshold);
	const isVerticalRef = useRef();
	// const isVertical = () => window.innerWidth < verticalThreshold;
	
	const [overflowX, setOverflowX] = useState('auto');
	const [overflowY, setOverflowY] = useState('auto');


	const currCategory = categories[category];
	const count = Object.keys(currCategory).length;
	const imagesPerWindow = imgPerWindow;

	const [offset, setOffset] = useState(0);
	const [randoms, setRandoms] = useState([]);
	const [categoryItemsShuffled, setCategoryItemsShuffled] = useState([]);

	const maxOffset = useRef(0);
	const col = useRef(0);

	useEffect(() => {
		const _randoms = [];

		// eslint-disable-next-line no-unused-vars
		for (const key in categories[category]) {
			_randoms.push(Math.random());
			_randoms.push(Math.random());
			_randoms.push(Math.random());
			_randoms.push(Math.random());
		}

		setRandoms(_randoms);
		setCategoryItemsShuffled(shuffleArray(Object.keys(currCategory)));

		setOffset(0);
		easedX.current = 0;
		mouseX.current = 0;
		col.current = window.innerWidth / imagesPerWindow;
		maxOffset.current = (count + 1 - imagesPerWindow) * col.current / 2;

		const tempIsVert = window.innerWidth < verticalThreshold;
		isVerticalRef.current = tempIsVert;
		setOverflowX(() => isMobile && !tempIsVert  ? 'auto' : 'hidden');
		setOverflowY(() => tempIsVert  ? 'auto' : 'hidden');
	}, [category, currCategory, categories]);


	useEffect(() => {
		requestRef.current = requestAnimationFrame(animate);
		window.addEventListener('resize', handleResize);

		return () => {
			cancelAnimationFrame(requestRef.current);
			window.removeEventListener('resize', handleResize);
		};

	}, []); // Make sure the effect runs only once

	const animate = () => {
		if (!isMobile && !isVerticalRef.current) {
			const dx = mouseX.current - easedX.current;
			easedX.current = easedX.current + dx * mouseSpeed;
			setOffset(() => -easedX.current * maxOffset.current);
		}
		requestRef.current = requestAnimationFrame(animate);
	};



	const trackMouse = function (e) {
		let x = e.clientX;

		let normalized = (x - window.innerWidth / 2) / (window.innerWidth / 2);
		mouseX.current = normalized * 1.2;
	};

	const onScroll = () => {
		if (isVerticalRef.current){
			setOffset((window.innerHeight / 3) * divRef.current.scrollTop / divRef.current.offsetHeight);
		}
		else{
			setOffset((window.innerWidth / 3) * divRef.current.scrollLeft / divRef.current.offsetWidth);
		}
	};

	const handleResize = () => {
		const tempIsVert = window.innerWidth < verticalThreshold;
		isVerticalRef.current = tempIsVert;
		setIsVertical(() => tempIsVert);
		setOverflowX(() => isMobile && !tempIsVert  ? 'auto' : 'hidden');
		setOverflowY(() => tempIsVert  ? 'auto' : 'hidden');
		col.current = window.innerWidth / imagesPerWindow;
		maxOffset.current = (count + 1 - imagesPerWindow) * col.current / 2;
	};

	return (
		<div className={navGrid} onMouseMove={trackMouse} ref={divRef} onScroll={onScroll} style={{ 
			'--overflowX': overflowX,
			'--overflowY': overflowY,
		}}>
			{categoryItemsShuffled.map((_item, _idx) => {
				const item = currCategory[_item];
				return (
					<GridItem key={item.meta.slug} item={item} idx={_idx} colSize={col.current} randoms={randoms} count={count} imagesPerWindow={imagesPerWindow} offset={offset} isMobile={isMobile} isVertical={isVertical } />
				);
			}
			)}
			{categoryItemsShuffled.slice(0, Math.ceil(count / itemToSquareRatio)).map((_item, _idx) => {
				const item = currCategory[_item];
				return (
					<GridRect key={item.meta.slug} idx={_idx} colSize={col.current} itemToSquareRatio={itemToSquareRatio} randoms={randoms} count={count} imagesPerWindow={imagesPerWindow} offset={offset} isMobile={isMobile} isVertical={isVertical } />
				);
			}
			)}
		</div>
	);
};


export default NavGrid;
