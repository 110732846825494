import React from 'react';
import { Link } from 'react-router-dom';
import ProjectColumns, { Column } from 'src/Components/ProjectColumns';
import Seo from 'src/Components/Seo';

const NotFound = () => (
	<>
		<Seo
			title={'404'}
		/>
		<ProjectColumns>
			<Column className="notfound">
				<h1>404: NOT FOUND</h1>
				<Link to={'/'}>RETURN TO HOME</Link>
			</Column>
		</ProjectColumns>
	</>
);

export default NotFound;